import React from "react"
import { BannerSection } from "./banner-section"
import { graphql, useStaticQuery } from "gatsby"
import { unoptimzeGatsbyImageSource } from "../helpers/common"
import Img from "gatsby-image"
import DotsSvg from "../assets/svg/contact-hero-new-dots.svg"

export const BannerContact = props => {
  const data = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "contact-hero-new-bg.png" }) {
        childImageSharp {
          original {
            src
            width
          }
          fluid(maxWidth: 1700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <BannerSection
      {...props}
      alternativeBg={
        <div className="contact-bg desktop">
          <Img
            fluid={unoptimzeGatsbyImageSource(data?.img?.childImageSharp)}
            alt={props.alt}
          />
          <DotsSvg />
        </div>
      }
    />
  )
}
